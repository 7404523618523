// is not necessary to add the base url, it will be added in the apiClient
export const apiUrls = {
  auth: {
    login: 'users/magic-link-checker/',
    user: (userId: string | number) => `v2/users/${userId}/`,
  },
  users: {
    getPedingContent: 'users/:user_pk/modules/pending/delivery/',
    pending: 'users/pending-content',
    generateContentLink: (moduleId: number) =>
      `users/:user_pk/modules/${moduleId}/content`,
    points: 'users/:user_pk/points/',
    streak: 'users/:user_pk/user-streak/',
    config: 'users/config',
    points_logger: 'users/:user_pk/historical-point-logs/',
    npsOption: 'users/:user_pk/nps/options/',
    ranking: 'v2/users/:user_pk/ranking',
    notifications:
      'users/:user_pk/notifications?is_sent=true&ordering=-created&is_deleted=false&is_seen=false',
    preferences: 'users/preferences/',
    restrictions: 'users/preferences/restrictions',
    avatars: (slugname: string) => `companies/config/${slugname}/avatars`,
  },
  content: {
    assests: (contentDeliveryPk: number) =>
      `contents/${contentDeliveryPk}/complementaries/`,
    addToFavorite: (contentId: number) => `contents/${contentId}/favorite/`,
    detail: (deliveryId: string) => `content/${deliveryId}/`,
    thread: {
      get: (contentId: number, page: number) =>
        `contents/${contentId}/threads?ordering=-created&page=${page}`,
      create: (contentId: number) => `contents/${contentId}/threads/`,
      like: (threadId: number) => `threads/${threadId}/like/`,
      remove: (threadId: number) => `threads/${threadId}/`,
    },
    canUnlock: 'users/:user_pk/routes/unlock-next-content',
  },
  comments: {
    create: (thread: number) => `threads/${thread}/comment/`,
    remove: (commentId: number) => `comments/${commentId}/`,
  },
  changeModuleAndCourse: {
    getActives: `users/:user_pk/active-module`,
    availableRoutes: (offset: number, query = '', status = '') => {
      return `users/:user_pk/routes/availables/?limit=50&offset=${offset}&search=${query}&status=${status}`;
    },
    availableCourses: (route: number) =>
      `users/:user_pk/routes/${route}/v2?search`,
    restrictAccess: `users/:user_pk/can-change`,
  },
  routes: {
    active: `users/:user_pk/active-route`,
  },
  products: {
    list: (companySlug: string) => `companies/${companySlug}/products`,
    details: ({ company_slug, product_id }: RedemptionParams) =>
      `companies/${company_slug}/products/${product_id}/`,
    redemption: ({ company_slug, product_id }: RedemptionParams) =>
      `companies/${company_slug}/products/${product_id}/redemption/`,
  },
};

type RedemptionParams = {
  company_slug: string;
  product_id: number;
};
